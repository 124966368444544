<div class="new-gas-stats">
  <div class="gas-calculation-chunk">
    <div class="dive-stat">
      <span matTooltip="Oxygen partial pressure of the new gas at the current depth" matTooltipPosition="above">
        <!-- eslint-disable-next-line prettier/prettier -->
        PO<sub>2</sub>:
        <strong>{{ newGasPO2 | number: '1.2-2' }}</strong>
      </span>
      <mat-icon *ngIf="hasNewGasPO2Warning" class="warning-icon" [matTooltip]="newGasPO2Warning!" matTooltipPosition="right">warning</mat-icon>
      <mat-icon *ngIf="hasNewGasPO2Error" class="error-icon" [matTooltip]="newGasPO2Error!" matTooltipPosition="right">error</mat-icon>
    </div>
    <div class="dive-stat">
      <span matTooltip="Equivalent narcotic depth of the new gas at the current depth" matTooltipPosition="above">
        END:
        <strong>{{ newGasEND }}m</strong>
      </span>
      <mat-icon *ngIf="hasNewGasENDError" class="error-icon" [matTooltip]="newGasENDError!" matTooltipPosition="right">error</mat-icon>
    </div>
    <div class="dive-stat">
      <span
        matTooltip="The amount of time you can stay at the current depth on the new gas and safely ascend directly to the surface (Note: this takes into account off-gassing that occurs during ascent)"
        matTooltipPosition="right">
        No Deco Limit:
        <strong>{{ noDecoLimit }}</strong>
      </span>
    </div>
  </div>
  <div class="gas-calculation-chunk">
    <div class="dive-stat">
      <span [matTooltip]="divePlanner.settings.MaxDepthPO2Tooltip" matTooltipPosition="right">
        <!-- eslint-disable-next-line prettier/prettier -->
        Max Depth (PO<sub>2</sub>):
        <strong>{{ newGas.maxDepthPO2 }}m ({{ newGas.maxDepthPO2Deco }}m deco)</strong>
      </span>
    </div>
    <div class="dive-stat">
      <span [matTooltip]="divePlanner.settings.MaxDepthENDTooltip" matTooltipPosition="right">
        Max Depth (END):
        <strong>{{ newGas.maxDepthEND }}m</strong>
      </span>
    </div>
    <div class="dive-stat">
      <span [matTooltip]="divePlanner.settings.MinDepthTooltip" matTooltipPosition="right">
        Min Depth (Hypoxia):
        <strong>{{ newGas.minDepth }}m</strong>
      </span>
    </div>
  </div>
</div>
