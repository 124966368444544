<mat-radio-group [(ngModel)]="newGasSelectedOption" (change)="onGasTypeChange()">
  <div class="new-gas-input">
    <div class="new-gas-col">
      <mat-radio-button value="current">
        <div>Current Gas</div>
        <div class="gas-description" [innerHTML]="currentGas.description"></div>
      </mat-radio-button>
      <mat-radio-button
        value="optimal"
        id="optimal-deco-gas-radio"
        matTooltip="The gas with the maximum O2 while staying under {{
          divePlanner.settings.decoPO2Maximum
        }} PO2, and the minimum Helium while staying at an END (Equivalent Narcotic Depth) of <= {{ divePlanner.settings.ENDErrorThreshold }}m">
        <div>Optimal Deco Gas</div>
        <div class="gas-description" [innerHTML]="optimalGas.description"></div>
      </mat-radio-button>
    </div>
    <div class="new-gas-col">
      <div>
        <mat-radio-button value="standard">
          <div>Standard Gas</div>
        </mat-radio-button>
      </div>
      <div>
        <mat-form-field>
          <mat-select
            [(ngModel)]="standardGas"
            hideSingleSelectionIndicator
            (selectionChange)="onStandardGasSelectionChange()"
            [disabled]="isStandardGasDisabled"
            class="standard-gas-dropdown">
            <mat-select-trigger>
              <div>{{ standardGas?.name }}</div>
              <div class="gas-description" [innerHTML]="standardGas?.compositionDescription"></div>
            </mat-select-trigger>
            <mat-option *ngFor="let gas of StandardGases" [value]="gas">
              <div>{{ gas.name }}</div>
              <div class="gas-description" [innerHTML]="gas.compositionDescription"></div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="new-gas-col">
      <mat-radio-button value="custom">Custom gas</mat-radio-button>
      <dive-custom-gas-input [disabled]="isCustomGasDisabled" (gasChanged)="onCustomGasChanged($event)"></dive-custom-gas-input>
    </div>
  </div>
</mat-radio-group>
