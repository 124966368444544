<div class="dive-plan-list-section">
  <mat-selection-list class="mat-elevation-z2">
    <mat-list-item *ngFor="let planEvent of planEvents">
      <span matListItemTitle>
        <mat-icon class="segment-icon">{{ planEvent.Icon }}</mat-icon>
        {{ planEvent.StartTimestamp | colonDuration }} {{ planEvent.Title }}
      </span>
      <span matListItemLine [innerHTML]="planEvent.Description" class="segment-description"></span>
    </mat-list-item>
  </mat-selection-list>
  <div class="segment-buttons">
    <button mat-fab color="primary" routerLink="/change-depth" class="add-segment-button" matTooltip="Ascend/Descend">
      <mat-icon>height</mat-icon>
    </button>
    <button mat-fab color="primary" routerLink="/change-gas" class="add-segment-button" matTooltip="Change Gas">
      <mat-icon>air</mat-icon>
    </button>
    <button mat-fab color="primary" routerLink="/maintain-depth" class="add-segment-button" matTooltip="Maintain Depth">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
