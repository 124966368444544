<main>
  <div class="main-content">
    <dive-current-stats class="mat-elevation-z2"></dive-current-stats>
    <h1>Select new gas</h1>
    <dive-new-gas-input (newGasSelected)="onNewGasSelected($event)" class="mat-elevation-z2"></dive-new-gas-input>
    <dive-new-gas-stats class="mat-elevation-z2" [newGas]="newGas"></dive-new-gas-stats>
    <div class="actions">
      <button mat-flat-button color="primary" (click)="onSave()">Save</button>
      <button mat-stroked-button color="secondary" routerLink="/dive-overview">Cancel</button>
    </div>
  </div>
</main>
