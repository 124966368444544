<div class="current-stats">
  <div class="dive-stat">
    Current Depth:
    <strong>{{ currentDepth }}m</strong>
  </div>
  <div class="dive-stat">
    <span
      matTooltip="The amount of time you can stay at the current depth on the current gas and safely ascend directly to the surface (Note: this takes into account off-gassing that occurs during ascent)"
      matTooltipPosition="right">
      No Deco Limit:
      <strong>{{ noDecoLimit }}</strong>
    </span>
  </div>
  <div class="dive-stat">
    <span
      matTooltip="The shallowest depth you can safely ascend directly to. This takes into account any on/off-gassing that may occur during the ascent. (Instantaneous Ceiling = {{
        instantCeiling
      }}m)"
      matTooltipPosition="right">
      Current Ceiling:
      <strong>{{ currentCeiling }}m</strong>
    </span>
  </div>
  <div class="dive-stat">
    Current Gas:
    <strong [innerHTML]="currentGas.description"></strong>
  </div>
  <div class="gas-stat dive-stat">
    <span [matTooltip]="divePlanner.settings.MaxDepthPO2Tooltip" matTooltipPosition="right">
      <!-- eslint-disable-next-line prettier/prettier -->
      Max Depth (PO<sub>2</sub>):
      <strong>{{ currentGas.maxDepthPO2 }}m ({{ currentGas.maxDepthPO2Deco }}m deco)</strong>
    </span>
  </div>
  <div class="gas-stat dive-stat">
    <span [matTooltip]="divePlanner.settings.MaxDepthENDTooltip" matTooltipPosition="right">
      Max Depth (END):
      <strong>{{ currentGas.maxDepthEND }}m</strong>
    </span>
  </div>
  <div class="gas-stat dive-stat">
    <span [matTooltip]="divePlanner.settings.MinDepthTooltip" matTooltipPosition="right">
      Min Depth (Hypoxia):
      <strong>{{ currentGas.minDepth }}m</strong>
    </span>
  </div>
  <div class="dive-stat">
    <span matTooltip="Oxygen partial pressure of the current gas at the current depth" matTooltipPosition="right">
      <!-- eslint-disable-next-line prettier/prettier -->
      PO<sub>2</sub>:
      <strong>{{ currentPO2 | number: '1.2-2' }}</strong>
    </span>
    <mat-icon *ngIf="hasCurrentPO2Warning" class="warning-icon" [matTooltip]="currentPO2Warning!" matTooltipPosition="right">warning</mat-icon>
    <mat-icon *ngIf="hasCurrentPO2Error" class="error-icon" [matTooltip]="currentPO2Error!" matTooltipPosition="right">error</mat-icon>
  </div>
  <div class="dive-stat">
    <span matTooltip="Equivalent narcotic depth of the current gas at the current depth" matTooltipPosition="right">
      END:
      <strong>{{ currentEND }}m</strong>
    </span>
    <mat-icon *ngIf="hasCurrentENDError" class="error-icon" [matTooltip]="currentENDError!" matTooltipPosition="right">error</mat-icon>
  </div>
</div>
