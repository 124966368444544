<main>
  <div class="dive-plan-section">
    <dive-dive-plan></dive-dive-plan>
    <dive-dive-summary></dive-dive-summary>
  </div>
  <div class="graphs">
    <dive-error-list></dive-error-list>
    <dive-depth-chart></dive-depth-chart>
    <dive-po2-chart></dive-po2-chart>
    <dive-end-chart></dive-end-chart>
    <dive-tissues-ceiling-chart></dive-tissues-ceiling-chart>
    <dive-tissues-pn2-chart></dive-tissues-pn2-chart>
    <dive-tissues-phe-chart></dive-tissues-phe-chart>
  </div>
</main>
