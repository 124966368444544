<div class="gas-calculations">
  <div class="gas-calc" [matTooltip]="divePlanner.settings.MaxDepthPO2Tooltip" matTooltipPosition="right">
    <!-- eslint-disable-next-line prettier/prettier -->
    Max Depth (PO<sub>2</sub>):
    <strong>{{ gas.maxDepthPO2 }}m ({{ gas.maxDepthPO2Deco }}m deco)</strong>
  </div>
  <div class="gas-calc" [matTooltip]="divePlanner.settings.MaxDepthENDTooltip" matTooltipPosition="right">
    Max Depth (END):
    <strong>{{ gas.maxDepthEND }}m</strong>
  </div>
  <div class="gas-calc">
    <span [matTooltip]="divePlanner.settings.MinDepthTooltip" matTooltipPosition="below">
      Min Depth (Hypoxia):
      <strong>{{ gas.minDepth }}m</strong>
    </span>
    <mat-icon [class.hidden]="!isMinDepthError()" class="error" matTooltip="This gas cannot be breathed at the surface due to the hypoxic oxygen content">
      error
    </mat-icon>
  </div>
</div>
