<div class="dive-settings">
  <h2>
    <mat-icon>settings</mat-icon>
    Dive Settings
  </h2>
  <div class="dive-settings-row">
    <div class="dive-settings-col">
      <mat-form-field
        class="settings-input"
        matTooltip="The maximum oxygen partial pressure during the working (i.e. non-deco) part of the dive. If any part of the dive plan exceeds this a warning will be shown. Default: 1.4"
        matTooltipPosition="right">
        <mat-label>Working PO2 Maximum</mat-label>
        <input matInput type="number" step=".1" [(ngModel)]="workingPO2Maximum" (input)="onWorkingPO2MaximumInput()" />
      </mat-form-field>
      <mat-form-field
        class="settings-input"
        matTooltip="The maximum oxygen partial pressure acceptable during deco stops. If any part of the dive plan exceeds this an error will be shown. Default: 1.6"
        matTooltipPosition="right">
        <mat-label>Deco PO2 Maximum</mat-label>
        <input matInput type="number" step=".1" [(ngModel)]="decoPO2Maximum" (input)="onDecoPO2MaximumInput()" />
      </mat-form-field>
      <mat-form-field
        class="settings-input"
        matTooltip="The minimum acceptable oxygen partial pressure. This is applicable when breathing hypoxic mixtures. Default: 0.18"
        matTooltipPosition="right">
        <mat-label>Minimum PO2</mat-label>
        <input matInput type="number" step=".01" [(ngModel)]="pO2Minimum" (input)="onPO2MinimumInput()" />
      </mat-form-field>
      <mat-form-field
        class="settings-input"
        matTooltip="If the equivalent narcotic depth (air) exceeds this value an error will be shown. Default: 30m"
        matTooltipPosition="right">
        <mat-label>Maximum END (m)</mat-label>
        <input matInput type="number" [(ngModel)]="ENDErrorThreshold" (input)="onENDErrorThresholdInput()" />
      </mat-form-field>
    </div>
    <div class="dive-settings-col">
      <mat-form-field class="settings-input">
        <mat-label>Descent Rate (m/min)</mat-label>
        <input matInput type="number" min="1" [(ngModel)]="descentRate" (input)="onDescentRateInput()" />
      </mat-form-field>
      <mat-form-field class="settings-input">
        <mat-label>Ascent Rate (m/min)</mat-label>
        <input matInput type="number" min="1" [(ngModel)]="ascentRate" (input)="onAscentRateInput()" />
      </mat-form-field>
      <mat-slide-toggle
        [(ngModel)]="isOxygenNarcotic"
        (change)="onOxygenNarcoticChange()"
        matTooltip="If this is turned off only the Nitrogen content will be considered when calculating the equivalent narcotic depth (END). If enabled both Oxygen and Nitrogen will be included. Default: On"
        matTooltipPosition="right">
        <span class="toggle-label">Is Oxygen Narcotic?</span>
      </mat-slide-toggle>
    </div>
  </div>
</div>
