<main>
  <header>
    <h1 id="select-starting-gas-heading">Select the starting gas for the dive</h1>
  </header>
  <mat-radio-group aria-labelledby="select-starting-gas-heading" [(ngModel)]="gasType">
    <div class="standard-gas">
      <div class="radio-button">
        <mat-radio-button name="gasType" value="standard">Standard gas</mat-radio-button>
      </div>
      <dive-standard-gas-list
        (gasSelected)="onStandardGasSelected($event)"
        [disabled]="getStandardGasDisabled()"
        class="mat-elevation-z2"></dive-standard-gas-list>
    </div>
    <div class="custom-gas-col">
      <div class="custom-gas">
        <div class="radio-button">
          <mat-radio-button value="custom">Custom gas</mat-radio-button>
        </div>
        <dive-custom-gas-input (gasChanged)="onCustomGasChanged($event)" [disabled]="getCustomGasDisabled()"></dive-custom-gas-input>
      </div>
      <dive-dive-settings class="mat-elevation-z2"></dive-dive-settings>
    </div>
    <div class="summary-data">
      <dive-start-gas-stats class="mat-elevation-z2" [gas]="getSelectedGas()"></dive-start-gas-stats>
      <div class="save-section">
        <button mat-flat-button color="primary" (click)="onSave()">Save</button>
      </div>
    </div>
  </mat-radio-group>
</main>
