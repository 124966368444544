<main>
  <div class="main-content">
    <dive-current-stats class="mat-elevation-z2"></dive-current-stats>
    <h1>Select time at depth</h1>
    <div class="time-at-depth-row">
      <div class="time-input-col">
        <div class="input-section mat-elevation-z2">
          <mat-form-field class="time-input">
            <mat-label>Time at Depth (mins)</mat-label>
            <input matInput type="number" [(ngModel)]="timeAtDepth" />
          </mat-form-field>
        </div>
        <dive-new-time-stats class="mat-elevation-z2" [timeAtDepth]="timeAtDepth"></dive-new-time-stats>
      </div>
      <dive-ceiling-chart class="mat-elevation-z2" [timeAtDepth]="timeAtDepth"></dive-ceiling-chart>
    </div>
    <div class="actions">
      <button mat-flat-button color="primary" (click)="onSave()">Save</button>
      <button mat-stroked-button color="secondary" routerLink="/dive-overview">Cancel</button>
    </div>
  </div>
</main>
