<mat-selection-list [multiple]="false" hideSingleSelectionIndicator (selectionChange)="onGasChange($event)" [disabled]="disabled">
  <mat-list-option
    *ngFor="let gas of standardGases"
    [value]="gas"
    [selected]="gas === selectedGas"
    matTooltipClass="tooltip-multiline tooltip-wide"
    matTooltipPosition="left"
    [matTooltip]="getGasTooltip(gas)">
    <span matListItemTitle>{{ gas.name }}</span>
    <span matListItemLine [innerHTML]="gas.compositionDescription"></span>
  </mat-list-option>
</mat-selection-list>
