<div class="new-time-stats">
  <div class="dive-stat">
    <span
      matTooltip="The amount of additional time you can stay at the current depth on the current gas and safely ascend directly to the surface (Note: this takes into account off-gassing that occurs during ascent)"
      matTooltipPosition="right">
      No Deco Limit:
      <strong>{{ noDecoLimit }}</strong>
    </span>
  </div>
  <div class="dive-stat">
    <span
      matTooltip="The shallowest depth you can safely ascend directly to. This takes into account any on/off-gassing that may occur during the ascent. (Instantaneous Ceiling = {{
        instantCeiling
      }}m)"
      matTooltipPosition="right">
      Ceiling:
      <strong>{{ ceiling }}m</strong>
    </span>
  </div>
  <div class="dive-stat">
    <span matTooltip="Total dive runtime after the time at depth" matTooltipPosition="right">
      Total Dive Duration:
      <strong>{{ totalDiveDuration | humanDuration }}</strong>
    </span>
  </div>
  <mat-divider *ngIf="hasDecoMilestones"></mat-divider>
  <div class="dive-stat deco-milestone" *ngFor="let milestone of decoMilestones">
    <span [matTooltip]="milestone.tooltip" matTooltipPosition="right">
      <strong>{{ milestone.duration | humanDuration }}</strong>
      : {{ milestone.gas }} &#64; {{ milestone.depth }}m
    </span>
  </div>
</div>
